import { useEffect } from 'react'
import Router from 'next/router'
import { pagesPath } from 'utils/$path'

const Index = () => {
  useEffect(() => {
    // ホームにリダイレクトさせる
    Router.push(pagesPath.portal.$url())
  }, [])

  return null
}

export default Index
